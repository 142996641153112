<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cart</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Cart</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Cart Area -->
<section class="shopping-cart-area ptb-100">
    <div class="container">
        <form class="cart-controller">
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Product name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead> 
                                                            
                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shopping-cart/shopping-cart-1.jpg" alt="Image">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Protective gloves</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$20.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class='bx bx-minus' ></i>
                                    </span>

                                    <input type="text" min="1" value="1">

                                    <span class="plus-btn">
                                        <i class='bx bx-plus'></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$20.00</span>
                                <a routerLink="/cart" class="remove">
                                    <i class="bx bx-trash"></i>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shopping-cart/shopping-cart-2.jpg" alt="Image">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Infrared thermometer gun</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$99.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class='bx bx-minus' ></i>
                                    </span>

                                    <input type="text" min="1" value="1">

                                    <span class="plus-btn">
                                        <i class='bx bx-plus' ></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$99.00</span>
                                <a routerLink="/cart" class="remove">
                                    <i class="bx bx-trash"></i>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shopping-cart/shopping-cart-3.jpg" alt="Image">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Cosmetic containers</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$150.0</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class='bx bx-minus'></i>
                                    </span>

                                    <input type="text" min="1" value="1">

                                    <span class="plus-btn">
                                        <i class='bx bx-plus'></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$150.0</span>
                                <a routerLink="/cart" class="remove">
                                    <i class="bx bx-trash"></i>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shopping-cart/shopping-cart-4.jpg" alt="Image">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Metal stethoscope</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$120.0</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class='bx bx-minus'></i>
                                    </span>

                                    <input type="text" min="1" value="1">

                                    <span class="plus-btn">
                                        <i class='bx bx-plus'></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$120.0</span>
                                <a routerLink="/cart" class="remove">
                                    <i class="bx bx-trash"></i>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shopping-cart/shopping-cart-5.jpg" alt="Image">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/single-products">Hand sanitizer gel</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$30.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn">
                                        <i class='bx bx-minus'></i>
                                    </span>

                                    <input type="text" min="1" value="1">

                                    <span class="plus-btn">
                                        <i class='bx bx-plus'></i>
                                    </span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$30.00</span>
                                <a routerLink="/cart" class="remove">
                                    <i class="bx bx-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="coupon-cart">
                <div class="row">
                    <div class="col-lg-8 col-sm-7">
                        <div class="form-group mb-0">
                            <input type="text" class="form-control" placeholder="Coupon code">
                            <a routerLink="/" class="default-btn">Apply coupon</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-5 text-right">
                        <a routerLink="/cart" class="default-btn update">
                            Update cart
                        </a>
                    </div>
                </div>
            </div>
        </form>
            
        <div class="cart-totals">
            <h3 class="cart-checkout-title">Checkout summary</h3>

            <ul>
                <li>Subtotal <span>$419.00</span></li>
                <li>Shipping <span>$00.00</span></li>
                <li>Total <span>$419.00</span></li>
                <li><b>Payable Total</b> <span><b>$419.00</b></span></li>
            </ul>

            <a routerLink="/checkout" class="default-btn two">
                Proceed to checkout
            </a>
        </div>
    </div>
</section>
<!-- End Cart Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->