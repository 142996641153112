<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">FAQ</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container">
        <div class="faq-bg">
            <div class="section-title">
                <h2>Les questions les plus posées</h2>
            </div>

            <div class="faq-accordion">
                <ul class="accordion">
                    <li class="accordion-item">
                        <a class="accordion-title active" href="javascript:void(0)">
                            <i class="bx bx-plus"></i>
                            Est ce que il y a des consultations en ligne?
                        </a>

                        <div class="accordion-content show">
                            <span>HYGINAT:</span>
                            <p>Oui. On peut faire une consultation par Zoom. Ce type de consultation
                                peut être interressent au deuxieme rendez vous de suivi.</p>
                        </div>
                    </li>

                    <li class="accordion-item">
                        <a class="accordion-title" href="javascript:void(0)">
                            <i class="bx bx-plus"></i>
                            Est ce qu'il y a des consultation à domicile?
                        </a>

                        <div class="accordion-content">
                            <span>HYGINAT:</span>
                            <p>Oui. Si le consultant le souhaite, je peux me déplacer chez lui. Uniquement en Ile de France.</p>                        </div>
                    </li>


                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
