<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Testimonials</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Testimonial -->
<section class="testimonial-area testimonial-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="testimonial-content">
                    <i class="flaticon-straight-quotes"></i>
                    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                    <h3>Markus Twain</h3>
                    <img src="assets/img/testimonial/signature.png" alt="Image">
                </div>
            </div>
            
            <div class="col-12">
                <div class="testimonial-content">
                    <i class="flaticon-straight-quotes"></i>
                    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                    <h3>Anna Deo</h3>
                    <img src="assets/img/testimonial/signature.png" alt="Image">
                </div>
            </div>
    
            <div class="col-12">
                <div class="testimonial-content">
                    <i class="flaticon-straight-quotes"></i>
                    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                    <h3>Kilvis Smith</h3>
                    <img src="assets/img/testimonial/signature.png" alt="Image">
                </div>
            </div>

            <div class="col-12">
                <div class="testimonial-content">
                    <i class="flaticon-straight-quotes"></i>
                    <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                    <h3>Juhon Smith</h3>
                    <img src="assets/img/testimonial/signature.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->