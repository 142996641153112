<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Recover Password!</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Recover Password!</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Recover Password -->
<section class="user-area-style recover-password-area ptb-100">
    <div class="container">
        <div class="contact-form-action recover">
            <div class="form-heading text-center">
                <h3 class="form-title">Reset password!</h3>
                <p class="reset-desc">Enter the email of your account to reset the password. Then you will receive a link to email to reset the password. If you have any issue about reset password <a routerLink="/contact">contact us.</a></p>
            </div>

            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="name" placeholder="Enter Email Address">
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a class="now-log-in font-q" routerLink="/login">Log in your account</a>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <p class="now-register">
                            Not a member?
                            <a class="font-q" routerLink="/register">Registration</a>
                        </p>
                    </div>

                    <div class="col-12">
                        <button class="default-btn btn-two" type="submit">Reset password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Recover Password -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->