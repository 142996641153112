import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-filter',
  templateUrl: './portfolio-filter.component.html',
  styleUrls: ['./portfolio-filter.component.scss']
})
export class PortfolioFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
