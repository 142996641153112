<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Register</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Register</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Register -->
<section class="user-area-style ptb-100">
    <div class="container">
        <div class="contact-form-action">
            <div class="account-title">
                <h2>Registration</h2>
            </div>

            <form method="post">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Full name</label>
                            <input class="form-control" type="text" name="name">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label>Email address</label>
                            <input class="form-control" type="email" name="email">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label>Mobile no.</label>
                            <input class="form-control" type="text" name="Number">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="text" name="password">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-sm-6">
                                <button class="default-btn register" type="submit">Register now</button>
                            </div>

                            <div class="col-lg-6 col-sm-6 text-right">
                                <input id="remember-1" type="checkbox">
                                <label>Show password ?</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <p>Have an account? <a routerLink="/login">Login now!</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Register -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->