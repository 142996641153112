<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Mes services</span>
            <h2>Je vous propose ces services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/c3050.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Bioelectronique de Vincent</a></h3>
                    <p>Pour connaitre l'état de votre terrain.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/cc.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Cohérence cardiaque</a></h3>
                    <p>Pour gérer le stress avec la respiration.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/bach.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Fleurs de Bach et quantiques olfactifs</a></h3>
                    <p>Pour gérer les émotions.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/icon/alim.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Alimentation</a></h3>
                    <p>Pour avoir un physique dense et en forme.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/dyno.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Iridologie</a></h3>
                    <p>Pour faire un bilan iridologique. Faites parler vos iris, ils ont beaucoup d'informations</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/jacquier.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Bol d'air Jacquier</a></h3>
                    <p>Pour utiliser le bol d'air Jacquier. Oxygénez vos cellules en quelques minutes. Efficace pour les sportifs</p>
                </div>
            </div>


            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/zen.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Relaxation</a></h3>
                    <p>Techniques de Respiration, de relaxation, de méditations, ...</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/vent.png" alt="Image">
                    </div>
                    <h3><a routerLink="/bev-service">Ventouses</a></h3>
                    <p>Pour tester la réflexologie avec les ventouses.</p>
                </div>
            </div>

        </div>

    </div>

    <div class="services-shape"><img src="assets/img/services-shape.png" alt="Image"></div>
</section>
<!-- End Services -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
