import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    contactTel = '';
    contactEmail = '';
    horairesOuvrables = '';

    constructor() { }

    ngOnInit(): void {
        this.contactEmail = environment.contactEmail;
        this.contactTel = environment.contactTel;
        this.horairesOuvrables = environment.horairesOuvrables;
    }

}
