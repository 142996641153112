<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Naturopathie</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><a routerLink="/blog-three-column">Articles</a></li>
                <li class="active">Naturopathie</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-details-content">
                    <div class="blog-details-img">
                        <img src="assets/img/blog-details/blog-details-img.jpg" alt="Image">
                    </div>

                    <div class="blog-top-content">
                        <div class="news-content">
                            <!--ul class="admin">
                                <li>
                                    <a routerLink="/">
                                        <i class="bx bx-user-circle"></i>
                                        Andrew Lawson
                                    </a>
                                </li>

                                <li>
                                    <a routerLink="/">
                                        <i class="bx bx-comment"></i>
                                        No comments
                                    </a>
                                </li>

                                <li class="float">
                                    <i class="bx bx-calendar-alt"></i>
                                    September 07, 2020
                                </li>
                            </ul-->

                            <h3>It might be time to seek a medical your muscle and joint pain</h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>

                            <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>

                        <blockquote>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. accumsan lacus vel facilisis.</p>
                            <i class="flaticon-straight-quotes"></i>
                        </blockquote>

                        <div class="news-content-2">
                            <h3>High quality work for our customers.</h3>
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-1.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-2.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="news-content-3">
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>
                    </div>

                    <!--div class="social">
                        <span>Share this post:</span>

                        <ul>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-pinterest-alt"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="comments">
                        <h3>Comments (2)</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog-details/comments-img-1.jpg" alt="Image">
                                <h3>Juanita Jones</h3>
                                <span>Monday, August 20, 2020</span>

                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>

                                <a routerLink="/blog-details">
                                    Reply
                                </a>
                            </li>

                            <li>
                                <img src="assets/img/blog-details/comments-img-2.jpg" alt="Image">
                                <h3>Ward F. Nelson</h3>
                                <span>Monday, August 20, 2020</span>

                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>

                                <a routerLink="/blog-details">
                                    Reply
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="leave-reply">
                        <h3>Leave a reply</h3>
                        <p>Your email address will not be published. Required fields are marked*</p>

                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Name*</label>
                                        <input type="text" name="name" id="name" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email address*</label>
                                        <input type="email" name="email" id="email" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Your website</label>
                                        <input type="text" name="your-website-link" id="your-website-link" class="form-control">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <textarea name="message" class="form-control" id="message" rows="8"></textarea>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="checkbox" id="chb2">
                                        <span>
                                            Save my name, email, and website in this browser for the next time I comment.
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn">
                                        Post a comment
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div-->
                </div>
            </div>

            <!--div class="col-lg-4">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li>
                                <a routerLink="/">Healthcare center</a>
                            </li>
                            <li>
                                <a routerLink="/"> Dental care</a>
                            </li>
                            <li>
                                <a routerLink="/">Cancer care</a>
                            </li>
                            <li>
                                <a routerLink="/">Immediate care</a>
                            </li>
                            <li>
                                <a routerLink="/">Diagnostic center</a>
                            </li>
                            <li>
                                <a routerLink="/">Pediatric services</a>
                            </li>
                            <li>
                                <a routerLink="/">Cardiology services</a>
                            </li>
                            <li>
                                <a routerLink="/">Physical therapy</a>
                            </li>
                            <li>
                                <a routerLink="/">Rehavilitation</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags">
                        <h3>Tags</h3>

                        <ul>
                            <li>
                                <a routerLink="/">Equipment</a>
                            </li>
                            <li>
                                <a routerLink="/">Health</a>
                            </li>
                            <li>
                                <a routerLink="/">Mask</a>
                            </li>
                            <li>
                                <a routerLink="/">Gloves</a>
                            </li>
                            <li>
                                <a routerLink="/">Virus</a>
                            </li>
                            <li>
                                <a routerLink="/">Cancer care</a>
                            </li>
                            <li>
                                <a routerLink="/">Thermometer</a>
                            </li>
                            <li>
                                <a routerLink="/">Medical</a>
                            </li>
                            <li>
                                <a routerLink="/">Doctor</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget gallery mb-0">
                        <h3 class="widget-title">Gallery</h3>

                        <ul>
                            <li>
                                <img src="assets/img/gallery/gallery-1.jpg" alt="Image">
                            </li>
                            <li>
                                <img src="assets/img/gallery/gallery-2.jpg" alt="Image">
                            </li>
                            <li>
                                <img src="assets/img/gallery/gallery-3.jpg" alt="Image">
                            </li>
                            <li>
                                <img src="assets/img/gallery/gallery-4.jpg" alt="Image">
                            </li>
                            <li>
                                <img src="assets/img/gallery/gallery-5.jpg" alt="Image">
                            </li>
                            <li>
                                <img src="assets/img/gallery/gallery-6.jpg" alt="Image">
                            </li>
                        </ul>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</div>
<!-- End Blog Details Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
