import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    contactTel = '';
    contactEmail = '';
    name = '';
    address = '';
    horairesOuvrables = '';
    horairesNonOuvrables = '';

    constructor() { }

    ngOnInit(): void {
        this.contactEmail = environment.contactEmail;
        this.contactTel = environment.contactTel;
        this.name = environment.name;
        this.address = environment.address;
        this.horairesOuvrables = environment.horairesOuvrables;
        this.horairesNonOuvrables = environment.horairesNonOuvrables;
    }

}
