<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>My Account</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">My Account</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Log In -->
<section class="user-area-style ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-form-action mb-50">
                    <div class="account-title">
                        <h2>Log in</h2>
                    </div>

                    <form method="post">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Email or Phone</label>
                                    <input class="form-control" type="text" name="name">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="password" name="password">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="login-action">
                                    <span class="log-rem">
                                        <input id="remember" type="checkbox">
                                        <label for="remember">Remember me!</label>
                                    </span>
                                    
                                    <span class="forgot-login">
                                        <a href="recover-password.html">Forgot your password?</a>
                                    </span>
                                </div>
                            </div>

                            <div class="col-12">
                                <button class="default-btn" type="submit">Log in now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="contact-form-action">
                    <div class="account-title">
                        <h2>Registration</h2>
                    </div>

                    <form method="post">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Full name</label>
                                    <input class="form-control" type="text" name="name">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Email address</label>
                                    <input class="form-control" type="email" name="email">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Mobile no.</label>
                                    <input class="form-control" type="text" name="Number">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Password</label>
                                    <input class="form-control" type="text" name="password">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="row align-items-center">
                                    <div class="col-lg-6 col-sm-6">
                                        <button class="default-btn register" type="submit">Register Now</button>
                                    </div>

                                    <div class="col-lg-6 col-sm-6 text-right">
                                        <input id="remember-1" type="checkbox">
                                        <label for="remember">Show password ?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Log In -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->