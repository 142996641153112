<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>La Bioélectronique de Vincent</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li><a routerLink="/services-style-one">Services</a></li>
                <li class="active">BEV</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <div class="portfolio-details-img">
                <img src="assets/img/bev-detail-service.png" alt="Image">
            </div>

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>Protocole pour Liquides physiologique</h3>

                    <p>Je vous donnerais le protocole détaillé, une fois le RDV confirmé. Les urines et la salive
                        devront être prélévées à jeûn.</p>

                    <p>Je fais des mesures de la salive et des urines. Cela nous donne une indication
                        de la digestion et du système d'élimination.</p>

                </div>

                <div class="news-content-2">
                    <h3>Mesures et interprétation.</h3>
                    <p>
                        Une fois les mesures des paramètres biolélectronique établies, je vous donnerais votre bilan qui
                        comporte l'interprétation des chiffres et des conseils de redressement de terrain.
                    </p>
                </div>

                <div class="news-content-3">
                    <h3>Tarif</h3>

                    <ul>
                        <li>
                            <i class="bx bx-check"></i>Liquides physiologiques : <span>100€</span>
                        </li>
                        <li>
                            <i class="bx bx-check"></i>Eau : <span>20€</span>
                        </li>
                    </ul>

                </div>

            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
