<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Departments</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Our Departments</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services -->
<section class="services-area services-area-two bg-t ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our departments</span>
            <h2>All the services that I provide to my patients in the field of treatment</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Cardiology</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Neurology</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-3.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Cancer care</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-4.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Laboratory</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-5.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Dental</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-6.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Pediatric</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-7.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">Pregnancy</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-8.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/department-details">X-ray</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/departments" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/departments" class="page-numbers">2</a>
                    <a routerLink="/departments" class="page-numbers">3</a>
                    <a routerLink="/departments" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->

<!-- Appointment -->
<section class="appointment-area pb-100">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-6">
                        <h3>Book an appointment</h3>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <select>
                                <option value="">Doctor Name</option>
                                <option value="">Dr.Juhon Dew</option>
                                <option value="">Dr.Jon Smith Care</option>
                                <option value="">Dr.Ana</option>
                                <option value="">Dr.Kilva</option>
                                <option value="">Dr.Zilka Smith</option>
                                <option value="">Dr.Jilva Dew</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <div class="input-group date" id="datetimepicker">
                                <input type="text" class="form-control" placeholder="07/09/2020">
                                <span class="input-group-addon"></span>
                            </div>	
                            <i class="bx bx-calendar"></i>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6">
                        <button type="submit" class="default-btn">Book Now</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->