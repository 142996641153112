import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bach-service',
  templateUrl: './bach-service.component.html',
  styleUrls: ['./bach-service.component.scss']
})
export class BachServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
