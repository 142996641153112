<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Services Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services Details -->
<div class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/services-details">Healthcare center</a></li>
                            <li><a routerLink="/services-details"> Dental care</a></li>
                            <li><a routerLink="/services-details">Cancer care</a></li>
                            <li><a routerLink="/services-details">Immediate care</a></li>
                            <li><a routerLink="/services-details">Diagnostic center</a></li>
                            <li><a routerLink="/services-details">Pediatric services</a></li>
                            <li><a routerLink="/services-details">Cardiology services</a></li>
                            <li><a routerLink="/services-details">Physical therapy</a></li>
                            <li><a routerLink="/services-details">Rehavilitation</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget appointment-box">
                        <div class="appointment-form">
                            <h3>Book an appointment</h3>

                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select Department</option>
                                        <option value="">Cardiology</option>
                                        <option value="">Neurology</option>
                                        <option value="">Cancer Care</option>
                                        <option value="">Laboratory</option>
                                        <option value="">Dental</option>
                                        <option value="">Neurology</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Service</option>
                                        <option value="">Services Style One</option>
                                        <option value="">Services Style Two</option>
                                        <option value="">Services Style Three</option>
                                        <option value="">Services Style Four</option>
                                        <option value="">Services Style Five</option>
                                        <option value="">Services Style Six</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Location</option>	
                                        <option value="">London</option>
                                        <option value="">United Kingdom</option>
                                        <option value="">United States</option>
                                        <option value="">Canada</option>
                                        <option value="">Australia</option>
                                        <option value="">California </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Preferred Time</option>
                                        <option value="">8.00 AM To 9.00 AM</option>
                                        <option value="">9.00 AM To 10.00 AM</option>
                                        <option value="">11.00 AM To 12.00 AM</option>
                                        <option value="">12.00 AM To 1.00 PM</option>
                                        <option value="">1.00 PM To 2.00 PM</option>
                                        <option value="">2.00 PM To 3.00 pm</option>
                                        <option value="">3.00 PM To 4.00 PM</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <div class="input-group date" id="datetimepicker">
                                        <input type="text" class="form-control" placeholder="07/09/2020">
                                        <span class="input-group-addon"></span>
                                        <i class="bx bx-calendar"></i>
                                    </div>	
                                </div>
                        
                                <button type="submit" class="default-btn">
                                    Book Appointment
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-details-img">
                        <img src="assets/img/services-details/services-details.jpg" alt="Image">
                    </div>

                    <div class="services-top-content">
                        <div class="news-content">
                            <h3>It Might Be Time To Seek A Medical Your Muscle And Joint Pain</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>
                            <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>

                        <div class="news-content-2">
                            <h3>High quality work for our customer.</h3>
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-services-post-img">
                                    <img src="assets/img/services-details/services-1.jpg" alt="Image">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-services-post-img">
                                    <img src="assets/img/services-details/services-2.jpg" alt="Image">
                                </div>
                            </div>
                        </div>

                        <div class="news-content">
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>

                        <div class="news-content-3">
                            <h3>What Can I Expect At My Initial Consultation?</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>
                            <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>

                        <div class="news-content-3">
                            <h3>Some Of Our Other Treatments</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>
                            <ul>
                                <li>
                                    <i class="bx bx-check"></i>
                                    White Fillings
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Emergency Dental Treatment
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Crowns & Bridges
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Conscious Sedation
                                </li>
                                <li>
                                    <i class="bx bx-check"></i>
                                    Air Polish
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Details -->

<!-- Subscribe -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="assets/img/subscribe-img.png" alt="Image">
                        <h3>Sing Up For Newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter email address" name="EMAIL">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>	
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->