<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Mentions légales et RGPD</h2>
            <ul>
                <li><a routerLink="/">Acceuil</a></li>
                <li class="active">Mentions légales et RGPD</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Privacy Policy Area -->
<section class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-policy">
            <div class="title">
                <h2>Mentions légales et RGPD</h2>
                <p>Mise à jour Aout 2023.</p>
            </div>

            <div class="privacy-content">
                <h3>1 – Édition du site</h3>
                <p>
                    En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique,
                    il est précisé aux utilisateurs du site internet https://www.hyginat.com  l’identité des différents
                    intervenants dans le cadre de sa réalisation et de son suivi.
                </p>
                <p>
                    Propriétaire du site : HYGINAT – gueye_sane@hotmail.com – Adresse des bureaux : 77240 Cesson
                </p>
                <p>
                    Identification de l’entreprise : HYGINAT – SIRET : 8023547610002
                </p>
                <p>
                    Directeur de la publication et Éditeur du site : Sané GUEYE
                </p>
                <p>
                    Délégué à la protection des données : Sané GUEYE
                </p>
                <p>
                    Hébergeur : OVH
                </p>
            </div>
            <div class="privacy-content">
                <h3>2 – Propriété intellectuelle et contrefaçons</h3>

                <p>
                    HYGINAT est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur
                    tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos,
                    vidéos, architecture, icônes et sons.
                </p>
                <p>
                    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des
                    éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation
                    écrite préalable de HYGINAT.
                </p>
                <p>
                    Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera
                    considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des
                    articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                </p>
            </div>

            <div class="privacy-content">
                <h3>3 – Limitations de responsabilité.</h3>

                <p>
                    HYGINAT ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de
                    l’utilisateur, lors de l’accès au site https://www.hyginat.com
                </p>
                <p>
                    HYGINAT décline toute responsabilité quant à l’utilisation qui pourrait être faite des informations
                    et contenus présents sur https://www.hyginat.com
                </p>
                <p>
                    HYGINAT s’engage à sécuriser au mieux le site https://www.hyginat.com, cependant sa responsabilité
                    ne pourra être mise en cause si des données indésirables sont importées et installées sur son site
                    à son insu.
                </p>
                <p>
                    Des espaces interactifs (espace contact ou commentaires) sont à la disposition des utilisateurs.
                    HYGINAT se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans
                    cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions
                    relatives à la protection des données.
                </p>
                <p>
                    Le cas échéant, HYGINAT se réserve également la possibilité de mettre en cause la responsabilité
                    civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux,
                    diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie …).
                </p>
            </div>

            <div class="privacy-content">
                <h3>4 – CNIL et gestion des données personnelles - Détails paragraphe 7</h3>
                <p>
                    Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’utilisateur du site
                    https://www.hyginat.com dispose d’un droit d’accès, de modification et de suppression des informations
                    collectées. Pour exercer ce droit, envoyez un message à notre Délégué à la Protection des Données
                </p>
                <p>
                    Pour plus d’informations sur la façon dont nous traitons vos données (type de données, finalité, destinataire…),
                    lisez notre Politique de Confidentialité.
                </p>
                <p>
                    Il est également possible de déposer une réclamation auprès de la CNIL.
                </p>
            </div>

            <div class="privacy-content">
                <h3>5 – Liens hypertextes et cookies</h3>
                <p>
                    Le site https://www.hyginat.com contient des liens hypertextes vers d’autres sites et dégage toute
                    responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers
                    https://www.hyginat.com
                </p>
                <p>
                    La navigation sur le site https://www.hyginat.com est susceptible de provoquer l’installation de
                    cookie(s) sur l’ordinateur de l’utilisateur.
                </p>
                <p>
                    Un « cookie » est un fichier de petite taille qui enregistre des informations relatives à la
                    navigation d’un utilisateur sur un site. Les données ainsi obtenues permettent d’obtenir des mesures
                    de fréquentation, par exemple.
                </p>
                <p>
                    Vous avez la possibilité d’accepter ou de refuser les cookies en modifiant les paramètres de votre
                    navigateur. Aucun cookie ne sera déposé sans votre consentement.
                </p>
                <p>
                    Les cookies sont enregistrés pour une durée maximale de 6 mois.
                </p>
                <p>
                    Pour plus d’informations sur la façon dont nous faisons usage des cookies, lisez notre Politique de Confidentialité.
                </p>
            </div>

            <div class="privacy-content">
                <h3>6 – Droit applicable et attribution de juridiction.</h3>
                <p>
                    Tout litige en relation avec l’utilisation du site https://www.hyginat.com est soumis au droit
                    français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de
                    juridiction aux tribunaux compétents de Valence.
                </p>
            </div>

            <div class="privacy-content">
                <h3>7 - Politique RGPD</h3>

                <h4>
                    La réglementation concernant le traitement des données à caractère personnel évolue à l’occasion de l’entrée en vigueur, le 25 mai 2018, du Règlement Général sur la Protection des Données (RGPD).
                </h4>
                <p>
                    <strong>Quelles données collectons nous ?</strong><br>
                    Les données personnelles que nous recueillons se limitent à celles strictement nécessaires pour vos
                    actions en lien avec HYGINAT. Selon votre cas : contact HYGINAT pour prise de rendez-vous de consultation.
                </p>
                <p>
                    <strong>Comment utilisons nous les données collectées ?</strong><br>
                    HYGINAT s’engage à utiliser les données collectées dans le strict cadre de son activité :
                    pour permettre de vous recontacter, pour vous envoyer des lettres d’informations,
                    et en tant qu’adhérent, pour permettre à HYGINAT de conduire sa mission.
                </p>
                <p>
                    <strong>Avec qui partageons nous vos données personnelles ?  (les données ne sont ni louées et ni cédées)</strong><br>
                    HYGINAT s’engage à utiliser les données collectées dans le strict cadre de son activité :
                    En vue d’accomplir les finalités précitées, nous sommes susceptibles de communiquer vos données à caractère personnel aux :
                    Prestataires de services, délégataires et sous-traitants des prestations pour notre compte,
                    Autorités financières, judicaires ou agence d’Etat, organismes public (sur demande et dans la limite de ce qui est permis par la réglementation)
                </p>
                <p>
                    <strong>Combien de temps conservons-nous vos données ?</strong><br>
                    Conformément à notre registre RGPD HYGINAT, vos données seront conservées 10 ans.
                </p>

                <p>
                    <strong>HYGINAT vous informe de votre droit d’opposition</strong><br>
                    Conformément à la réglementation applicable, vous disposez des différents droits suivants :
                </p>
                <ul>
                    <li><span>- </span> <strong>Droit d’accès :</strong> vous pouvez obtenir des informations concernant le traitement de vos données à caractère personnel ainsi qu’une copie de ces données personnelles.</li>
                    <li><span>- </span> <strong>Droit de rectification :</strong> si vous estimez que vos données à caractère personnel sont inexactes ou incomplètes, vous pouvez exiger que ces données à caractère personnel soient modifiées en conséquence.</li>
                    <li><span>- </span> <strong>Droit à l’effacement :</strong> vous pouvez exiger l’effacement de vos données à caractère personnel dans la limite de ce qui est permis par la réglementation. Cette dernière prévoit notamment, que ce droit ne s’applique que lorsque les données concernées ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou au respect d’une obligation légale ou ne permettent plus la constatation, l’exercice ou la défense de droits en justice.</li>
                    <li><span>- </span> <strong>Droit à la limitation du traitement :</strong> vous pouvez demander la limitation du traitement de vos données à caractère personnel dans les conditions prévues par la réglementation applicable en matière de protection des données à caractère personnel des personnes physiques.</li>
                    <li><span>- </span> <strong>Droit d’opposition :</strong> dans le cadre des finalités exposées plus haut, vous pouvez vous opposer au traitement de vos données à caractère personnel, pour des motifs liés à votre situation particulière. Vous disposez du droit de vous opposer au traitement de vos données à caractère personnel à des fins de prospection commerciale y compris le profilage lié à cette prospection.</li>
                    <li><span>- </span> <strong>Droit à la portabilité de vos données :</strong> lorsque vous avez donné votre consentement à l’utilisation de données à caractère personnel, vous avez le droit que ces données soient transmises directement à un autre responsable de traitement si cela est possible techniquement.</li>
                    <li><span>- </span> <strong>Droit de retirer votre consentement :</strong> lorsque vous avez donné votre consentement au traitement de ces données à caractère personnel, vous avez le droit de retirer votre consentement à tout moment.</li>
                </ul>


                <p>
                    <strong>Si vous souhaitez exercer l’un de ces droits, vous pouvez adresser votre demande par courrier à adresse postale :</strong><br>
                    Hyginat, 18 rue de la Montgolfière, 77240 Cesson
                </p>
                <p>
                    Consultez le site de la <a href="https://www.cnil.fr/fr/rgpd-de-quoi-parle-t-on" target="_blank">CNIL</a>  pour plus d’informations sur vos droits, pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif.
                </p>



            </div>


        </div>
    </div>
</section>
<!-- End Privacy Policy Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
