<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contactez moi</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Contact</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <!--div class="col-lg-8">
                <div class="contact-form">
                    <div class="contact-title">
                        <h2>Vous voulez me contactez? Laissez moi un message</h2>
                    </div>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Nom et Prénom</label>
                                    <input type="text" name="name" id="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" name="email" id="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Sujet</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Téléphone</label>
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="10"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn btn-two">
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div-->

            <div class="col-lg-12">
                <div class="address-and-week">
                    <p>24/7 vous pouvez me concater svp.</p>

                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Tél: </span>
                                <a href="tel:{{contactTel}}">{{contactTel}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{contactEmail}}">{{contactEmail}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Adresse: </span>
                                {{address}}
                            </li>
                            <!--li>
                                <i class="flaticon-clock"></i>
                                <a href="https://www.kalae.com/fr_FR/praticiens/sane-gueye-cesson" target="_blank"><span style="color: darkred">PRENDRE UN RENDEZ-VOUS</span></a>
                            </li-->
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42241.66540029222!2d2.5543352824765218!3d48.56955519048106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e5e520061f65dd%3A0x40b82c3688c61c0!2s77240%20Cesson!5e0!3m2!1sfr!2sfr!4v1653750871020!5m2!1sfr!2sfr"></iframe>
    <!--iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103674119!2d-73.91097366523668!3d40.85176866829554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1599153418461!5m2!1sen!2sbd"></iframe-->
</div>
<!-- End Map Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
