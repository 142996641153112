<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Style Three</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Services Style Three</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services -->
<section class="services-area services-area-two bg-t ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our services11</span>
            <h2>All the services that I provide to my patients in the field of treatment</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Cardiology</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Neurology</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-3.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Cancer care</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-4.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Laboratory</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-5.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Dental</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-6.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Pediatric</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-7.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Pregnancy</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services/services-8.jpg" alt="Image">
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">X-ray</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/services-style-thee" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services-style-thee" class="page-numbers">2</a>
                    <a routerLink="/services-style-thee" class="page-numbers">3</a>
                    <a routerLink="/services-style-thee" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
