<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Choose Us -->
<section class="choose-us-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Why choose us</span>
            <h2>A hospital is an institution where patients are treated. Our doctors are always engaged in this work</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img">
                    <img src="assets/img/choose-us-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content">
                    <h3>In remote & the around places who lives there, medical science is changing their medical care,education & research impact</h3>
                    <p>Our most experienced doctors,emergency doctors,nurses,therapist & researchers give Their Best in this hospital.they also provide advanced medicine facilities,campuses,clinics & classrooms.we give patients best innovative treatment and best service to improve the health and overall wellbeing of our community.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="choose-us-shape"><img src="assets/img/choose-us-shape.png" alt="Image"></div>
</section>
<!-- End Choose Us -->

<!-- Counter -->
<section class="counter-area pb-100">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-satisfy"></i>
                        <h2><span class="odometer" data-count="1688">00</span></h2>
                        <h3>Satisfied patients</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-doctor"></i>
                        <h2>
                            <span class="odometer" data-count="100">00</span> 
                            <span class="target">+</span>
                        </h2>
                        <h3>Medical specialist</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-hospital-bed"></i>
                        <h2><span class="odometer" data-count="347">00</span> </h2>
                        <h3>Medical beds</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-experience"></i>
                        <h2>
                            <span class="odometer" data-count="25">00</span> 
                            <span class="target">+</span>
                        </h2>
                        <h3>Years of experience</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->

<!-- Choose Us -->
<section class="choose-us-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content-two">
                    <span class="top-title">Why choose us</span>
                    <h2>A hospital is an institution where patients are treated. Our doctors are always engaged in this work</h2>
                    <p>We will transfer your prescriptions to the specified department and set up your service Lorem ipsum dolor sit amet consectetur adipisicing elit</p>
                    <ul>
                        <li><span>1</span> We coordinate</li>
                        <li><span>2</span> We schedule</li>
                        <li><span>3</span> We package</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img-two">
                    <img src="assets/img/choose-us-img-2.jpg" alt="Image">

                    <div class="address-and-week">
                        <p>For 24/7 emergency please call us. If is no emergency, contact at normal time.</p>
                        <div class="address-week">
                            <ul class="address">
                                <li>
                                    <i class="flaticon-telephone-1"></i>
                                    <span>Call:</span>
                                    <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                                </li>
                                <li>
                                    <i class="flaticon-arroba"></i>
                                    <span>Email:</span>
                                    <a href="mailto:support@scora.com">support@scora.com</a>
                                </li>
                                <li>
                                    <i class="flaticon-address-1"></i>
                                    <span>Address:</span>
                                    6890 Blvd, The Bronx, NY 1058 New York, USA
                                </li>
                            </ul>
                            <ul class="week">
                                <li>
                                    Weekdays:
                                    <span>8:00 am – 8:00 pm</span>
                                </li>
                                <li>
                                    Saturday:
                                    <span>9:00 am – 5:00 pm</span>
                                </li>
                                <li>
                                    Sunday:
                                    <span>9:00 am – 3:00 pm</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Choose Us -->

<!-- Appointment -->
<section class="appointment-area">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-6">
                        <h3>Book an appointment</h3>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <select>
                                <option value="">Doctor Name</option>
                                <option value="">Dr.Juhon Dew</option>
                                <option value="">Dr.Jon Smith Care</option>
                                <option value="">Dr.Ana</option>
                                <option value="">Dr.Kilva</option>
                                <option value="">Dr.Zilka Smith</option>
                                <option value="">Dr.Jilva Dew</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <div class="input-group date" id="datetimepicker">
                                <input type="text" class="form-control" placeholder="07/09/2020">
                                <span class="input-group-addon"></span>
                            </div>	
                            <i class="bx bx-calendar"></i>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6">
                        <button type="submit" class="default-btn">Book Now</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Doctors -->
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our specialists</span>
            <h2>We have specialist doctor’s for emergency services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cosmetic Surgeon</span>
                        <h3>Bernarda N. Bacon</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Surgery Surgeon</span>
                        <h3>Brian Christensen</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Dental surgeon</span>
                        <h3>Aimee C. Mayfield</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cardiothoracic Surgeon</span>
                        <h3>Gabriel Henderson</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctors -->

<!-- Online Treatment -->
<section class="online-treatment-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-img">
                    <img src="assets/img/online-treatment-img.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-content">
                    <span>See a doctor online</span>
                    <h2>There is an online doctor to provide medical services to patients 24 hours a day</h2>
                    <ul>
                        <li><i class="bx bx-check"></i> Doctors appointments normally</li>
                        <li><i class="bx bx-check"></i> Registered doctors</li>
                        <li><i class="bx bx-check"></i> Unlimited 24/7 video chat</li>
                        <li><i class="bx bx-check"></i> Prescription collection</li>
                        <li><i class="bx bx-check"></i> Message with your provider</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Online Treatment -->

<!-- Testimonial -->
<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-1.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Markus Twain</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-2.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Anna Deo</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="testimonial-img">
                        <img src="assets/img/testimonial/testimonial-3.jpg" alt="Image">
                        <div class="testimonial-shape">
                            <img src="assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                        <h3>Kilvis Smith</h3>
                        <img src="assets/img/testimonial/signature.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->

<!-- Start Partner Area -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider partner-bg owl-theme owl-carousel">
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-1.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-2.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-4.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-5.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
        </div>
    </div>

    <div class="partner-shape"><img src="assets/img/partner/partner-shape.png" alt="Image"></div>
</div>
<!-- End Partner Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->