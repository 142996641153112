<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Products Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Products Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Product Details Area -->
<section class="product-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <img src="assets/img/product-details/product-details-1.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3>Blue sterile gloves</h3>

                    <div class="product-review">
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </div>
                        <a routerLink="/single-products" class="rating-count">(3 Customer reviews)</a>
                    </div>

                    <div class="price">
                        <span class="new-price"> <del>$29.00</del> $20.00</span>
                    </div>

                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren at vero eos et accusam  amet, consetetur sadipscing elitr.</p>

                    <div class="product-add-to-cart">
                        <h3>Quantities:</h3>
                        <div class="input-counter">
                            <span class="minus-btn">
                                <i class="bx bx-minus"></i>
                            </span>

                            <input type="text" min="1" value="1">

                            <span class="plus-btn">
                                <i class="bx bx-plus"></i>
                            </span>
                        </div>
                    </div>

                    <button type="submit" class="default-btn">
                        Add to Cart
                    </button>

                    <ul class="social-wrap">
                        <li>
                            <span>Share:</span>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" target="_blank">
                                <i class="bx bxl-facebook"></i>
                            </a>
                        </li>
                    </ul>

                    <p class="category">Category: <span>Mask</span></p>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <ul class="tabs">
                                <li>
                                    <a href="#">
                                        Description
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Additional information
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        Reviews(2)
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="tab_content">
                                <div class="tabs_item">
                                    <div class="products-details-tab-content">
                                        <h3 class="mb-2">Description</h3>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero blanditiis quo fugiat magni accusamus exercitationem! Voluptate, deleniti quae. Libero iusto minus, nulla excepturi quidem reprehenderit blanditiis eligendi exercitationem nesciunt ad! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi temporibus deserunt mollitia natus esse, sunt fuga quos. Autem quasi error quisquam architecto fuga suscipit atque voluptatibus nobis impedit nulla. Officia exercitationem nesciunt ad.</p>

                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis repellat tempora aliquid exercitationem. Vel, dicta quos. Velit magnam, eius unde ullam, distinctio atque aperiam obcaecati ipsam id, inventore consectetur laudantium repellat tempora aliquid exercitationem. Vel, dicta quos. Velit magnam, eius unde ullam.</p>
                                    </div>
                                </div>

                                <div class="tabs_item">
                                    <div class="products-details-tab-content">
                                        <ul class="additional-information">
                                            <li><span>Brand:</span> ThemeForest</li>
                                            <li><span>Color:</span> Brown</li>
                                            <li><span>Size:</span> Large, Medium</li>
                                            <li><span>Weight:</span> 27 kg</li>
                                            <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="tabs_item">
                                    <div class="products-details-tab-content">
                                        <div class="product-review-form">
                                            <h3>Customer reviews</h3>

                                            <div class="review-title">
                                                <div class="rating">
                                                    <i class="bx bxs-star"></i>
                                                    <i class="bx bxs-star"></i>
                                                    <i class="bx bxs-star"></i>
                                                    <i class="bx bxs-star"></i>
                                                    <i class="bx bxs-star"></i>
                                                </div>
                                                <p>Based on 2 reviews</p>
                                            </div>

                                            <div class="review-comments">
                                                <div class="review-item">
                                                    <div class="rating">
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                    </div>

                                                    <h3>Good</h3>
                                                    <span><strong>Admin</strong> on <strong>July 21, 2020</strong></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>

                                                    <a routerLink="/" class="review-report-link">Reply</a>
                                                </div>

                                                <div class="review-item">
                                                    <div class="rating">
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                        <i class="bx bxs-star"></i>
                                                    </div>
                                                    
                                                    <h3>Good</h3>
                                                    <span><strong>Admin</strong> on <strong>July 21, 2020</strong></span>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>

                                                    <a routerLink="/" class="review-report-link">Reply</a>
                                                </div>
                                            </div>

                                            <div class="review-form">
                                                <h3>Write a Review</h3>

                                                <form>
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Name</label>
                                                                <input type="text" id="name" name="name" class="form-control">
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6 col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input type="email" id="email" name="email" class="form-control">
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Review title</label>
                                                                <input type="text" id="review-title" name="review-title" class="form-control">
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Body of review (1500)</label>
                                                                <textarea name="review-body" id="review-body" cols="30" rows="8" class="form-control"></textarea>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12 col-md-12">
                                                            <button type="submit" class="btn default-btn two">Submit Review</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Product Details Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->