<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cohérence Cardiaque</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Cohérence Cardiaque</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <div class="portfolio-details-img">
                <img src="assets/img/cc-detail-service.png" alt="Image">
            </div>

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>Compétence et Coaching en Cohérence Cardiaque et Cardiofeedback</h3>

                    <p>Je suis certifié dans la <strong>"Compétence Professionnelle en Cohérence Cardiaque et Cardiofeedback"</strong>,
                        pour accompagner des personnes à la pratique de la cohérence cardiaque dans un cadre professionnel.</p>

                    <p>Le <strong>Cardiofeedback</strong> est un outil (Heart Tracker, Inner balance, ...) qui nous permet de savoir si on est dans cet état de cohérence cardiaque.</p>

                </div>

                <div class="news-content-2">
                    <h3>Mise en place</h3>
                    <p>
                        Nous allons mettre en place la pratique de la méthode <strong>365</strong>. La cohérence cardiaque marche bien pour les personnes
                        qui cherchent à arrêter de fumer ou à diminuer leur poids, ...
                    </p>
                    <div class="portfolio-details-img">
                        <img src="assets/img/cc-detail-1.png" alt="Image">
                    </div>
                </div>

                <div class="news-content-2">
                    <h3>Les effets psychologiques à long terme</h3>
                    <ul>
                        <li><i class="bx bx-check"></i>Impression de calme et de sérénité</li>
                        <li><i class="bx bx-check"></i>Diminution du niveau de stress ressenti</li>
                        <li><i class="bx bx-check"></i>Augmentation de la capacité d'introspection</li>
                        <li><i class="bx bx-check"></i>Amélioration des relations interpersonnelles</li>
                        <li><i class="bx bx-check"></i>Augmentation de la juste perception de la réalité</li>
                        <li><i class="bx bx-check"></i>Amélioration de la clarté mentale</li>
                        <li><i class="bx bx-check"></i>Amélioration de la performance</li>
                        <li><i class="bx bx-check"></i>Diminution de l'anxiété et du stress perçus</li>
                        <li><i class="bx bx-check"></i>...</li>
                    </ul>
                </div>


            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
