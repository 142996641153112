import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

    contactTel = '';
    contactEmail = '';
    name = '';
    horairesOuvrables = '';
    horairesNonOuvrables = '';

    constructor() { }

    ngOnInit(): void {
        this.contactEmail = environment.contactEmail;
        this.contactTel = environment.contactTel;
        this.name = environment.name;
        this.horairesOuvrables = environment.horairesOuvrables;
        this.horairesNonOuvrables = environment.horairesNonOuvrables;
    }

}
