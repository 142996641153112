<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Banner -->
<section class="banner-area bg-3 banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-12">
                        <div class="banner-content">
                            <h1 class="wow fadeInDown" data-wow-delay="1s">We have highly experienced doctor in our hospital</h1>
                            <p class="wow fadeInLeft" data-wow-delay="1s">Our service and the work efficiency of our staff are the reasons for our success.</p>
                            <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
                                <a routerLink="/services-style-one" class="default-btn">Our services</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="appointment-box wow fadeInUp" data-wow-delay="1s">
                                    <p>Book an appointment</p>

                                    <div class="appointment-form">
                                        <form>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Your Name">
                                            </div>

                                            <div class="form-group">
                                                <select>
                                                    <option value="">Select Department</option>
                                                    <option value="">Cardiology</option>
                                                    <option value="">Neurology</option>
                                                    <option value="">Cancer Care</option>
                                                    <option value="">Laboratory</option>
                                                    <option value="">Dental</option>
                                                    <option value="">Neurology</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <select>
                                                    <option value="">Select A Service</option>
                                                    <option value="">Services Style One</option>
                                                    <option value="">Services Style Two</option>
                                                    <option value="">Services Style Three</option>
                                                    <option value="">Services Style Four</option>
                                                    <option value="">Services Style Five</option>
                                                    <option value="">Services Style Six</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <select>
                                                    <option value="">Select A Location</option>
                                                    <option value="">London</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United States</option>
                                                    <option value="">Canada</option>
                                                    <option value="">Australia</option>
                                                    <option value="">California </option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <select>
                                                    <option value="">Select A Preferred Time</option>
                                                    <option value="">8.00 AM To 9.00 AM</option>
                                                    <option value="">9.00 AM To 10.00 AM</option>
                                                    <option value="">11.00 AM To 12.00 AM</option>
                                                    <option value="">12.00 AM To 1.00 PM</option>
                                                    <option value="">1.00 PM To 2.00 PM</option>
                                                    <option value="">2.00 PM To 3.00 pm</option>
                                                    <option value="">3.00 PM To 4.00 PM</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <div class="input-group date" id="datetimepicker">
                                                    <input type="text" class="form-control" placeholder="07/09/2020">
                                                    <span class="input-group-addon"></span>
                                                    <i class="bx bx-calendar"></i>
                                                </div>
                                            </div>

                                            <button type="submit" class="default-btn">
                                                Book appointment
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="address-and-week wow fadeInDown" data-wow-delay="1s">
                                    <p>For 24/7 emergency please call us. If is no emergency, contact at normal time.</p>
                                    <div class="address-week">
                                        <ul class="address">
                                            <li>
                                                <i class="flaticon-telephone-1"></i>
                                                <span>Call:</span>
                                                <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                                            </li>
                                            <li>
                                                <i class="flaticon-arroba"></i>
                                                <span>Email:</span>
                                                <a href="mailto:support@vuci.com">support@vuci.com</a>
                                            </li>
                                            <li>
                                                <i class="flaticon-address-1"></i>
                                                <span>Address:</span>
                                                6890 Blvd, The Bronx, NY 1058 New York, USA
                                            </li>
                                        </ul>
                                        <ul class="week">
                                            <li>
                                                Monday:
                                                <span>8:00 am – 8:00 pm</span>
                                            </li>
                                            <li>
                                                Tuesday:
                                                <span>8:00 am – 8:00 pm</span>
                                            </li>
                                            <li>
                                                Sunday:
                                                <span>8:00 am – 8:00 pm</span>
                                            </li>
                                            <li>
                                                Wednesday:
                                                <span>9:00 am – 3:00 pm</span>
                                            </li>
                                            <li>
                                                Thursday:
                                                <span>8:00 am – 8:00 pm</span>
                                            </li>
                                            <li>
                                                Friday:
                                                <span>8:00 am – 8:00 pm</span>
                                            </li>
                                            <li>
                                                Saturday:
                                                <span>9:00 am – 5:00 pm</span>
                                            </li>
                                            <li>
                                                Sunday:
                                                <span>9:00 am – 3:00 pm</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape-3">
        <img src="assets/img/banner/banner-shape-3.png" alt="Image">
    </div>
</section>
<!-- End Banner -->

<!-- Partner -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider-two owl-theme owl-carousel">
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-1.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-2.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-4.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-5.png" alt="Image">
                </a>
            </div>
            <div class="partner-item">
                <a target="_blank" href="#">
                    <img src="assets/img/partner/partner-3.png" alt="Image">
                </a>
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->

<!-- Services -->
<section class="services-area services-area-two services-area-three ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our services33</span>
            <h2>All the services that I provide to my patients in the field of treatment</h2>
        </div>

        <div class="services-slider owl-theme owl-carousel">
            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/services-details"><img src="assets/img/services/services-1.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/services-details">Cardiology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/services-details"><img src="assets/img/services/services-2.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/services-details">Neurology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/services-details"><img src="assets/img/services/services-3.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/services-details">Cancer care</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/services-details"><img src="assets/img/services/services-4.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/services-details">Laboratory</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="single-services">
                <div class="services-img">
                    <a routerLink="/services-details"><img src="assets/img/services/services-5.jpg" alt="Image"></a>
                </div>
                <div class="services-content">
                    <h3><a routerLink="/services-details">Dental</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->

<!-- About Us -->
<section class="about-us-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img-1.jpg" alt="Image">
                    <div class="about-img-2">
                        <img src="assets/img/about-img-2.jpg" alt="Image">
                        <div class="about-post">
                            <h3>Dr. Gloria Williams</h3>
                            <span>Cosmetic Surgeon</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="top-title">About us</span>
                    <h2>Story about our professional Dr. Gloria Williams</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                    <div class="granted">
                        <span>100%</span>
                        <p>In my various experiences, cosmetic surgery has 100% success ipsum dolor sit amet, consectetur adipiscing</p>
                    </div>
                    <a routerLink="/about" class="default-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Us -->

<!-- Before And After -->
<section class="before-after-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Before & after</span>
            <h2>Before and after transformations</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut numquam eligendi rem cupiditate, dicta debitis saepe quae? Dicta incidunt nisi fugit distinctio dignissimos ipsum iste, magni animi</p>
        </div>

        <div class="before-after-img">
            <img src="assets/img/before-after-img.jpg" alt="Image">
            <ul>
                <li>Before</li>
                <li class="after">After</li>
            </ul>
        </div>
    </div>

    <div class="before-after-shape"><img src="assets/img/before-after-shape.png" alt="Image"></div>
</section>
<!-- End Before And After -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing plants</span>
            <h2>We have different types of pricing plans to meet patient needs and budget</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Starter</h3>
                    <h2><Sub>$</Sub>79</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal Fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing active">
                    <h3>Basic</h3>
                    <h2><Sub>$</Sub>99</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal Fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <h3>Premium</h3>
                    <h2><Sub>$</Sub>129</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>
                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal Fees</li>
                        <li>Routine checkup</li>
                    </ul>
                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Portfolio -->
<section class="our-portfolio-area our-portfolio-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our portfolio</span>
            <h2>Below ate some examples of all the portfolios we provide to our patients</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-1.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Conditions we treat</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-2.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Research and innovation</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-portfolio">
                    <img src="assets/img/portfolio/portfolio-3.jpg" alt="Image">
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Doctor examining physiotherapy </a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->

<!-- Counter -->
<section class="counter-area pb-100">
    <div class="container">
        <div class="counter-bg counter-bg-three">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-satisfy"></i>
                        <h2><span class="odometer" data-count="1688">00</span></h2>
                        <h3>Satisfied patients</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-doctor"></i>
                        <h2>
                            <span class="odometer" data-count="100">00</span>
                            <span class="target">+</span>
                        </h2>
                        <h3>Medical specialist</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-hospital-bed"></i>
                        <h2><span class="odometer" data-count="347">00</span> </h2>
                        <h3>Medical beds</h3>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-experience"></i>
                        <h2>
                            <span class="odometer" data-count="25">00</span>
                            <span class="target">+</span>
                        </h2>
                        <h3>Years of experience</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->

<!-- Online Treatment -->
<section class="online-treatment-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-img">
                    <img src="assets/img/online-treatment-img.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="online-treatment-content">
                    <span>See a doctor online</span>
                    <h2>There is an online doctor to provide medical services to patients 24 hours a day</h2>
                    <ul>
                        <li><i class="bx bx-check"></i> Doctors appointments normally</li>
                        <li><i class="bx bx-check"></i> Registered doctors</li>
                        <li><i class="bx bx-check"></i> Unlimited 24/7 video chat</li>
                        <li><i class="bx bx-check"></i> Prescription collection</li>
                        <li><i class="bx bx-check"></i> Message with your provider</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="online-treatment-shape-1"><img src="assets/img/online-treatment-shape-1.png" alt="Image"></div>
    <div class="online-treatment-shape-2"><img src="assets/img/online-treatment-shape-2.png" alt="Image"></div>
</section>
<!-- End Online Treatment -->

<!-- Doctors -->
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our specialists</span>
            <h2>We have specialist doctor’s for emergency services</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-1.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cosmetic Surgeon</span>
                        <h3>Bernarda N. Bacon</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-2.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Surgery Surgeon</span>
                        <h3>Brian Christensen</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-3.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Dental surgeon</span>
                        <h3>Aimee C. Mayfield</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-doctors">
                    <div class="doctors-img">
                        <img src="assets/img/doctors/doctors-4.jpg" alt="Image">
                        <ul>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        </ul>
                    </div>
                    <div class="doctors-content">
                        <span>Cardiothoracic Surgeon</span>
                        <h3>Gabriel Henderson</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctors -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Recent news</span>
            <h2>All the recent news about our treatment has been published</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>07</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">It might be time to seek a medical your muscle and joint pain</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Curtis Warren</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="Image"></a>
                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">The reassurance of a safe pregnancy through the birth of your baby</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">James Lopez</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="single-blog-post">
                    <div class="blog-content">
                        <span>General news</span>
                        <h3><a routerLink="/blog-details">Read up on different types of fevers that may require a doctor</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">Milton Baines</a></li>
                            <li class="read"><a routerLink="/blog-details">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<!-- Subscribe -->
<section class="subscribe-area pb-100">
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="assets/img/subscribe-img.png" alt="Image">
                        <h3>Sing up for newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter email address" name="email">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
