<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Fleurs de Bach / Quantiques Olfactifs</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li class="active">Fleurs de Bach</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <div class="portfolio-details-img">
                <img src="assets/img/bach-detail-service.png" alt="Image">
            </div>

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>Les fleurs de Bach</h3>
                    <p>Les 38 fleurs de Bach, ou élixirs floraux du Dr Bach, des élixirs élaborés dans les années 1930 par le Docteur Edward Bach,
                        un médecin homéopathe britannique.</p>
                    <p>Selon l'état de nos émotions, les élixirs des fleurs de Bach peuvent beaucoup nous aider. Chaque fleur a sa vibration qui peut résoudre
                    tel type de problématique émotionnel. Cette intuition et travail de ce docteur est juste impressionnant!</p>
                </div>
                <div class="news-content-2">
                    <h3>Quantiques Olfactifs</h3>
                    <p>L’olfactothérapie est une branche de l’aromathérapie s’appliquant à la sphère psycho-émotionnelle.
                    Elle utilise les odeurs et la vibration de certaines huiles essentielles afin d’offrir des clés pour mieux gérer notre comportement et
                        notre bien-être. Il s’agit d’une voie exceptionnelle pour libérer des blessures passées mais aussi pour le développement personnel.</p>

                    <p>Les 24 synergies des Quantiques Olfactifs sont des synergies d'huiles essentielles de haute qualité vibratoire et 100% pures, naturelles et biologiques.
                    </p>
                </div>

                <div class="news-content-3">
                    <h3>Mise en place</h3>
                    <p>
                        Nous allons mettre revoir ensemble les émotions pour déterminer les synérgies ou élixirs floraux qu'il faut.
                        Rappelons que dès fois des maladies physiques peuvent avoir leur origine dans la sphère émotionnelle.
                    </p>
                    <div class="portfolio-details-img">
                        <img src="assets/img/bach-detail-1.png" alt="Image">
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
