<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Portfolio Filter</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Portfolio Filter</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Our Portfolio Area -->
<section class="our-portfolio-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our portfolio</span>
            <h2>Below ate some examples of all the portfolios we provide to our patients</h2>
        </div>

        <div class="shorting-menu">
            <button class="filter" data-filter="all">
                All projects
            </button>

            <button class="filter" data-filter=".a">
                Conditions
            </button>

            <button class="filter" data-filter=".b">
                Research
            </button>

            <button class="filter" data-filter=".c">
                Facial
            </button>  

            <button class="filter" data-filter=".d">
                Cosmetic
            </button> 
        </div>

        <div class="shorting"> 
            <div class="row">
                <div class="col-lg-6 col-sm-6 a c d mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-1.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Conditions we treat
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6  b e mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-2.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Research and innovation
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 a c d mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-3.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Doctor examining physiotherapy 
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 b e mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-4.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Facial treatment 
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 a c d mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-5.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Cosmetic treatment 
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 b e mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-6.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Patient receiving massage
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 a c d mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-7.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Medicine and surgery 
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6 b e mix">
                    <div class="single-portfolio">
                        <img src="assets/img/portfolio/portfolio-8.jpg" alt="Image">

                        <div class="portfolio-content">
                            <h3>
                                <a routerLink="/portfolio-details">
                                    Physical therapy
                                </a>
                            </h3>
                        </div>
                    </div>
                </div>

                
                <div class="col-12 text-center">
                    <a href="#" class="default-btn">
                        Load more
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Portfolio Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->