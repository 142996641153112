<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Login</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Login</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Log In -->
<section class="user-area-style ptb-100">
    <div class="container">
        <div class="contact-form-action">
            <div class="account-title">
                <h2>Log in</h2>
            </div>

            <form method="post">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label>Email or Phone</label>
                            <input class="form-control" type="text" name="name">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="password" name="password">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="login-action">
                            <span class="log-rem">
                                <input id="remember" type="checkbox">
                                <label for="remember">Remember me!</label>
                            </span>
                            
                            <span class="forgot-login">
                                <a routerLink="/recover-password">Forgot your password?</a>
                            </span>
                        </div>
                    </div>

                    <div class="col-12">
                        <button class="default-btn" type="submit">Log in now</button>
                    </div>

                    <div class="col-12">
                        <p>Have an account? <a routerLink="/register">Registration Now!</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Log In -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->