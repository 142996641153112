<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Doctor Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Doctor Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Doctor Details -->
<section class="doctors-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="doctors-sidebar">
                    <div class="doctors-img">
                        <img src="assets/img/doctor-details/doctor-1.jpg" alt="Image">
                        <ul>
                            <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>

                    <div class="availability">
                        <h3><i class="bx bx-time"></i> Availability</h3>

                        <ul>
                            <li>
                                Monday - Friday
                                <span>9.00 - 20.00</span>
                            </li>
                            <li>
                                Saturday
                                <span>10.00 - 16.00</span>
                            </li>
                            <li>
                                Sunday
                                <span>9.30 - 18.00</span>
                            </li>
                            <li>
                                Friday
                                <span>Closed</span>
                            </li>
                        </ul>
                    </div>

                    <div class="appointment-box">
                        <p>Book an appointment</p>

                        <div class="appointment-form">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select Department</option>
                                        <option value="">Cardiology</option>
                                        <option value="">Neurology</option>
                                        <option value="">Cancer Care</option>
                                        <option value="">Laboratory</option>
                                        <option value="">Dental</option>
                                        <option value="">Neurology</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Service</option>
                                        <option value="">Services Style One</option>
                                        <option value="">Services Style Two</option>
                                        <option value="">Services Style Three</option>
                                        <option value="">Services Style Four</option>
                                        <option value="">Services Style Five</option>
                                        <option value="">Services Style Six</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Location</option>	
                                        <option value="">London</option>
                                        <option value="">United Kingdom</option>
                                        <option value="">United States</option>
                                        <option value="">Canada</option>
                                        <option value="">Australia</option>
                                        <option value="">California </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Preferred Time</option>
                                        <option value="">8.00 AM To 9.00 AM</option>
                                        <option value="">9.00 AM To 10.00 AM</option>
                                        <option value="">11.00 AM To 12.00 AM</option>
                                        <option value="">12.00 AM To 1.00 PM</option>
                                        <option value="">1.00 PM To 2.00 PM</option>
                                        <option value="">2.00 PM To 3.00 pm</option>
                                        <option value="">3.00 PM To 4.00 PM</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <div class="input-group date" id="datetimepicker">
                                        <input type="text" class="form-control" placeholder="07/09/2020">
                                        <span class="input-group-addon"></span>
                                        <i class="bx bx-calendar"></i>
                                    </div>	
                                </div>
                        
                                <button type="submit" class="default-btn">Book appointment</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-lg-8 col-md-12">
                <div class="doctors-details mb-30">
                    <div class="doctors-history">
                        <h2>Dr. Oncologist</h2>
                        <span>MBBS (Sydney), FRACS (Paediatric Anastasia)</span>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam! Dicta rerum deserunt itaque. Incidunt in quo architecto eveniet rem facere, necessitatibus, dolorem voluptas deleniti iure fuga magni velit molestiae ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam adipisicing.</p>
                    
                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Specialty</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Endocrinology
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Paediatric medicine
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Urology
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Education</h3>
                                </div>
                            </div>
                            
                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Endocrinology
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Doctor of Medicine, University of texas, USA (1990)
                                        </li>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            Medical Orientation Program, St. Louis University (St. Louis, Missouri 1996)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Experience</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            25 Years of experience in medicine
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Address</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            6890 Blvd, The Bronx, NY 1058 New York, USA
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Phone</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Email</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            <a href="mailto:support@scora.com">support@scora.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row borders">
                            <div class="col-lg-3 col-md-12 pl-0">
                                <div class="left-title">
                                    <h3>Website</h3>
                                </div>
                            </div>

                            <div class="col-lg-9 col-md-12">
                                <div class="right-title">
                                    <ul>
                                        <li>
                                            <i class="bx bxs-hand-right"></i>
                                            <a href="#" target="_blank">www.scora.com</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="doctors-details">
                    <div class="doctors-history">
                        <h2>Dr. Oncologist</h2>
                        <span>MBBS (Sydney), FRACS (Paediatric Anastasia)</span>
                        <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam! Dicta rerum deserunt itaque. Incidunt in quo architecto eveniet rem facere, necessitatibus, dolorem voluptas deleniti iure fuga magni velit molestiae ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam adipisicing.</p>
                        <p class="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam! Dicta rerum deserunt itaque. Incidunt in quo architecto eveniet rem facere, necessitatibus, dolorem voluptas deleniti iure fuga magni velit molestiae ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam adipisicing.</p>
                        <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam! Dicta rerum deserunt itaque. Incidunt in quo architecto eveniet rem facere, necessitatibus, dolorem voluptas deleniti iure fuga magni velit molestiae ipsum dolor sit amet consectetur adipisicing elit. Repellat, totam adipisicing.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctor Details -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->