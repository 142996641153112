// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // contactEmail: 'contact@bioelectroniquemsg.org',
    contactEmail: 'gueye_sane@hotmail.com',
    contactTel: '+33789357799',
    name: 'Hygiènes Naturo',
    address: 'Cesson, 77240, France',
    shortName: 'HYGINAT',
    url: 'https://www.hyginat.com',
    horairesOuvrables: '08H:00-20H:00',
    horairesNonOuvrables: '10H:00-12H:00',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
