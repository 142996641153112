<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Portfolio Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Portfolio Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Portfolio Details Area -->
<div class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="portfolio-details-content">
            <div class="portfolio-details-img">
                <img src="assets/img/portfolio-details/portfolio-details.jpg" alt="Image">
            </div>

            <div class="portfolio-top-content">
                <div class="news-content">
                    <h3>It might be time to seek a medical your muscle and joint pain</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>

                    <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                </div>

                <div class="news-content-2">
                    <h3>High quality work for our customers.</h3>
                    <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                </div>
                
                <div class="news-content">
                    <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                </div>

                <div class="news-content-3">
                    <h3>What Can I Expect At My Initial Consultation?</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>

                    <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                </div>

                <div class="news-content-3">
                    <h3>Some of our other treatments</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>

                    <ul>
                        <li>
                            <i class="bx bx-check"></i>
                            White fillings
                        </li>
                        <li>
                            <i class="bx bx-check"></i>
                            Emergency dental treatment
                        </li>
                        <li>
                            <i class="bx bx-check"></i>
                            Crowns & bridges
                        </li>
                        <li>
                            <i class="bx bx-check"></i>
                            Conscious sedation
                        </li>
                        <li>
                            <i class="bx bx-check"></i>
                            Air polish
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Portfolio Details Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->